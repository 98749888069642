<template>
  <div class="vuse-content-wrapper">
    <smart-breadcrumbs :items="navigationElements" />
    <!-- Content -->
    <v-card>
      <div class="filters">
        <h2 class="text-center">
          Gestor de Documentos - <small>{{ company.name }}</small
          ><help
            class="mx-2"
            :helpId="67"
            :xSmall="true"
            style="vertical-align: baseline"
          />
          <v-tooltip top>
             <template v-slot:activator="{ on, attrs }">
              <v-btn small v-bind="attrs"
                v-on="on"
                color="primary"
                dark
                class="mb-2"
                style="float: right"
                @click.prevent="handleCreate()"
                >Crear
              </v-btn>
            </template>
            <span>Crear Documento</span>
          </v-tooltip>
        </h2>
        <label>Filtrar por:</label>
        <v-form ref="formCompany" v-model="valid" style="width: 100%" class="row mx-0">
          <v-row class="mx-0" style="max-width: calc(100% - 90px)">
            <v-col cols="12" lg="3" md="4" sm="6" style="padding: 1px 2px 1px 2px">
              <v-text-field
                dense
                solo
                hide-details="auto"
                label="Búsqueda"
                v-model="filters.search"
              />
            </v-col>
          </v-row>
          <v-btn
            color="primary"
            style="min-width: auto; position: absolute; right: 50px; height: 25px"
            dark
            class="px-1 py-1"
            v-if="filterActivated"
            @click.prevent="
              filterActivated = false
              cleanFilters()
              getDocuments()
            "
            ><v-icon dark> mdi-delete </v-icon>
          </v-btn>
          <v-tooltip top>
             <template v-slot:activator="{ on, attrs }">
              <v-btn small v-bind="attrs"
                v-on="on"
                color="primary"
                style="min-width: auto; position: absolute; right: 10px; height: 25px"
                dark
                class="px-1 py-1"
                @click.prevent="
                  filterActivated = true
                  getDocuments()
                "
                ><v-icon dark> mdi-filter </v-icon>
              </v-btn>
            </template>
            <span>Filtrar</span>
          </v-tooltip>
        </v-form>
      </div>

      <simple-list
        :title="''"
        :dense="true"
        :headers="headers"
        :items="items"
        :totalItems="totalItems"
        :itemsPerPage="itemsPerPage"
        :loadingText="loadingText"
        :itemKey="'id'"
        :hideFooter="true"
        :hideHeader="false"
        :multiSort="false"
        :loading="loading"
        :class="'my-2 pb-2'"
        :sortable="false"
        :page="page"
        :pages="pages"
        :extraButtoms="false"
        :showCreate="false"
        :showDelete="true"
        :buttonLink="'empresas/ficha'"
        :orderOptions="orderOptions"
        :orderBy="orderBy"
        @create:item="handleCreate()"
        @edit:item="handleEdit($event)"
        @delete:item="deleteItem($event)"
        @update:page="pagination($event)"
        @orderEvent="handleOrder($event)"
      />
    </v-card>
    <modal name="crudModal" :height="'80%'" :width="'90%'" :adaptive="true">
      <v-card class="pa-4" elevation="0">
        <h2 class="text-center mb-4" v-if="flow === 'create'">Crear Documento</h2>
        <h2 class="text-center mb-4" v-if="flow === 'edit'">Editar Documento</h2>

        <span v-if="flow === 'edit' && item.created_at"
          >Fecha de creación: {{ toLocaleDateString(createdAt, 'es-VE') }}.
          {{ dateRange > 0 ? 'Hace ' + dateRange + ' dias' : '' }}</span
        >

        <v-form ref="formCompany" v-model="valid" style="width: 100%">
          <v-row class="mx-0 mt-2">
            <v-col cols="12" lg="4" md="6" sm="12">
              <v-row style="margin: 0px">
                <label style="margin: 0px auto; font-size: 13px; width: 40%"
                  >Empresa</label
                >
                <multiselect
                  style="width: 60%"
                  v-model="assignedCompany"
                  label="name"
                  track-by="id"
                  placeholder="Buscar"
                  open-direction="bottom"
                  :options="companies"
                  :multiple="false"
                  :searchable="true"
                  :loading="isLoading"
                  :internal-search="false"
                  :clear-on-select="true"
                  :close-on-select="true"
                  :options-limit="300"
                  :limit="3"
                  :limit-text="limitText"
                  :max-height="600"
                  :show-no-results="false"
                  :hide-selected="true"
                  @load="getCompanies"
                  @search-change="getCompanies"
                >
                  <template slot="tag" slot-scope="{ option, remove }"
                    ><span class="custom__tag"
                      ><span>{{ option.name }}</span
                      ><span class="custom__remove" @click="remove(option)"
                        >❌</span
                      ></span
                    ></template
                  >
                  <template slot="clear" slot-scope="">
                    <div
                      class="multiselect__clear"
                      v-if="positionId && positionId.length"
                    />
                  </template>
                  <span slot="noResult"
                    >No se encontraron elementos. Considere cambiar la consulta de
                    búsqueda.</span
                  >
                </multiselect>
              </v-row>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                counter
                :rules="[rules.required, rules.max]"
                label="Nombre"
                v-model="name"
              />
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                counter
                :rules="[rules.required, rules.max]"
                label="Descripción"
                v-model="description"
              />
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                :rules="[rules.required]"
                label="Fecha de expiración"
                v-model="expiredAt"
                type="date"
              />
            </v-col>
          </v-row>
          <v-row class="mx-0" :key="documentIndex">
            <v-col cols="12" sm="6" md="4" v-if="flow === 'edit' && item.document"
              ><v-list-item link class="px-0">
                <v-btn icon>
                  <v-icon> mdi-folder </v-icon>
                </v-btn>
                <v-list-item-content>
                  <v-list-item-title>{{
                    item.document.name
                      ? item.document.name
                      : 'Archivo ' + (item.document.id + 1)
                  }}</v-list-item-title>

                  <v-list-item-subtitle
                    >{{ item.document.format }}
                    {{
                      item.document.size ? ' | ' + item.document.size + 'kB' : ''
                    }}</v-list-item-subtitle
                  >
                </v-list-item-content>

                <v-list-item-action>
                  <a :href="item.document.route" download style="text-decoration: none">
                    <v-btn icon small>
                      <v-icon color="grey lighten-1"> mdi-download </v-icon>
                    </v-btn>
                  </a>
                </v-list-item-action>
                <v-list-item-action>
                  <v-btn icon small @click="deleteFile(item.document)">
                    <v-icon color="red lighten-1"> mdi-delete </v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item></v-col
            >
            <v-col cols="6" sm="6" md="8"
              ><v-file-input
                v-model="file"
                chips
                :rules="[rules.fileSize]"
                ref="file"
                show-size
                accept=".doc,.docx,.pdf,.xls,.xlsx,.ppt,.pptx"
                :label="item.document ? 'Click para cambiar archivo' : 'Subir archivo'"
            /></v-col>
          </v-row>
        </v-form>
      </v-card>
      <v-col cols="12" style="margin-bottom: 20px; height: 10%">
        <div class="row">
          <v-btn small
            style="margin: auto; margin-top: 10px"
            v-if="flow === 'create'"
            :disabled="!valid"
            color="primary"
            @click="createItem()"
          >
            Crear
          </v-btn>
          <v-btn small
            style="margin: auto; margin-top: 10px"
            v-if="flow === 'edit'"
            :disabled="!valid"
            color="primary"
            @click="saveItem()"
          >
            Guardar
          </v-btn>

          <v-btn small
              style="margin: auto; margin-top: 10px" color="primary" @click="reset">
            Limpiar
          </v-btn>
          <v-btn small
            style="margin: auto; margin-top: 10px"
            color="primary"
            @click="$modal.hide('crudModal')"
          >
            Cancelar
          </v-btn>
        </div>
      </v-col>
    </modal>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import SimpleList from '@/components/Listing/SimpleList.vue'
  import { decryptData } from '@/utils/encryption'
  import { toLocaleDateString } from '@/utils/helpers'
  import Help from '@/components/App/Help'

  export default {
    name: 'documents',
    components: {
      SimpleList,
      Help,
    },
    data() {
      return {
        config: {},
        documentIndex: 0,
        loading: true,
        isLoading: false,
        headers: [
          {
            text: '#',
            value: 'id',
            align: ' d-none',
          },
          { text: 'Nombre', value: 'name' },
          { text: 'Fecha de expiración', value: 'expiredText' },
          { text: 'Acciones', value: 'actions', align: 'center', width: '120px' },
        ],
        items: [],
        // companies: [],
        navigationElements: [
          {
            text: 'Control y Supervisión',
            disabled: false,
            query: null,
            params: null,
            name: 'business/supervision-control/Dashboard',
            href: '/business/supervision-control/admin-panel',
          },
          {
            text: 'Mis documentos',
            disabled: true,
          },
        ],
        page: 1,
        pages: 1,
        item: {},
        assignedCompany: {},
        totalItems: 0,
        itemsPerPage: 10,
        flow: '',
        filterActivated: false,
        orderBy: { id: 1, name: 'Recientes', order: 'created_at', by: 'desc' },
        orderOptions: [
          { id: 1, name: 'Recientes', order: 'created_at', by: 'desc' },
          { id: 2, name: 'Antiguos', order: 'created_at', by: 'asc' },
          { id: 3, name: 'Nombre', order: 'name', by: 'asc' },
          { id: 4, name: 'Última Edición', order: 'updated_at', by: 'desc' },
        ],
        filters: {
          search: '',
        },
        expiredAt: '',
        name: '',
        description: '',
        createdAt: '',
        toDay: new Date(),
        dateRange: '',
        country: '',
        loadingText: 'Cargando registros',
        datas: [],
        file: null,
        valid: true,
        rules: {
          required: (v) => !!v || 'Requerido.',
          min: (v) => (v ? v.length >= 8 : true) || '8 caracteres como mínimo',
          max: (v) =>
            (v ? v.length <= 200 : true) || 'Debe poseer menos de 200 caracteres',
          maxNumber: (v) =>
            (v ? v.length <= 18 : true) || 'Debe poser menos de 18 caracteres',
          email: (v) => /.+@.+\..+/.test(v) || 'E-mail debe ser válido',
          fileSize: (v) =>
            (v ? v.size < 5120000 : true) ||
            'El peso del archivo debe ser inferior a 5MB',
        },
      }
    },
    created() {
      this.getDocuments()
    },
    watch: {
      company() {
        this.getDocuments()
      },
    },
    computed: {
      ...mapGetters(['userCurrentCompany']),
      company: {
        get() {
          return decryptData(this.userCurrentCompany)
        },
        set(newValue) {},
      },
    },
    methods: {
      toLocaleDateString: toLocaleDateString,

      cleanFilters() {
        this.filters = {
          search: '',
        }
      },

      async getDocuments() {
        this.loading = true
        let queryParams = ''
        let orderString = ''
        if (this.orderBy.order && this.orderBy.by) {
          orderString = '&order=' + this.orderBy.order + '&by=' + this.orderBy.by
        }
        if (this.filterActivated) {
          let searchString = ''
          if (this.filters.search !== '') {
            searchString = '&search=' + this.filters.search
          }
          queryParams = searchString
        }
        this.$axios
          .get(
            'documents?company_id=' +
              this.company.id +
              '&page=' +
              this.page +
              orderString +
              queryParams
          )
          .then((response) => {
            this.items = response.data.data
            if (this.items && this.items.length < 1) {
              this.loadingText = 'No hay registros'
            }
            this.totalItems = response.data.total
            this.itemsPerPage = response.data.per_page
            if (response.data.from) {
              this.page = response.data.current_page
              this.pages = response.data.last_page
            } else {
              this.page = 1
              this.pages = 1
            }
            this.items.forEach((item) => {
              item.expired_at = item.expired_at.split(' ')[0]
              item.expiredText = this.toLocaleDateString(item.expired_at)
              item.document = item.uploads.find((upload) => upload.type === 'document')
            })
            this.loading = false
          })
      },

      async handleCreate() {
        this.flow = 'create'
        this.item = {}
        this.name = ''
        this.description = ''
        this.expiredAt = ''
        this.file = null

        this.$modal.show('crudModal')
      },

      handleOrder(orderBy) {
        this.orderBy = orderBy
        this.getDocuments()
      },

      async handleEdit(item) {
        this.flow = 'edit'
        this.item = item
        this.name = this.item.name
        this.description = this.item.description
        this.expiredAt = this.item.expired_at
        this.file = null
        this.createdAt = new Date(this.item.created_at)
        this.dateRange = Math.ceil(
          Math.abs(this.createdAt - this.toDay) / (1000 * 60 * 60 * 24)
        )

        this.$modal.show('crudModal')
      },

      validate() {
        this.$refs.formCompany.validate()
      },

      reset() {
        this.$refs.formCompany.reset()
        this.$refs.formCompany.resetValidation()
      },

      async createItem() {
        this.config.headers = {
          'Content-Type': 'multipart/form-data',
          'X-Requested-With': 'XMLHttpRequest',
        }

        const formData = new FormData()
        formData.append('company_id', this.company.id)
        formData.append('name', this.name)
        formData.append('description', this.description)
        formData.append('expired_at', this.expiredAt)
        if (this.file && this.file !== '' && this.file !== undefined) {
          formData.append('file', this.file)
          formData.append('fileType', 'document')
          if (this.item.file) {
            formData.append('fileId', this.item.file.id)
          }
        }
        this.$axios.post('documents', formData, this.config).then(() => {
          this.page = 1
          this.getDocuments()
          this.$modal.hide('crudModal')
        })
      },

      async saveItem() {
        this.config.headers = {
          'Content-Type': 'multipart/form-data',
          'X-Requested-With': 'XMLHttpRequest',
        }

        const formData = new FormData()
        formData.append('company_id', this.company.id)
        formData.append('name', this.name)
        formData.append('description', this.description)
        formData.append('expired_at', this.expiredAt)
        if (this.file && this.file !== '' && this.file !== undefined) {
          formData.append('file', this.file)
          formData.append('fileType', 'document')
          if (this.item.file) {
            formData.append('fileId', this.item.file.id)
          }
        }
        formData.append('_method', 'patch')
        this.$axios.post('documents/' + this.item.id, formData, this.config).then(() => {
          this.page = 1
          this.getDocuments()
          this.$modal.hide('crudModal')
        })
      },

      async deleteItem(item) {
        this.$axios.delete('documents/' + item.id).then(() => {
          this.page = 1
          this.items = []
          this.getDocuments()
        })
      },

      async deleteFile(file) {
        this.$axios.delete('upload-management/delete-upload/' + file.id).then(() => {
          this.item.document = null
          this.documentIndex++
          this.$swal({
            icon: 'success',
            title: `Eliminado`,
            showCancelButton: false,
            showConfirmButton: true,
            timer: 2000,
          })
        })
      },

      async pagination(page) {
        this.page = page
        this.items = []
        await this.getDocuments()
      },
    },
  }
</script>
<style lang="scss">
  @import '@/sass/cruds/_crudStyles.scss';
</style>
